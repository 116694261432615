import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import { Input } from './Input';
import { cn } from '../../lib/utils';

const NumberInput = React.forwardRef(({ className, isLoading, onChange, ...props }, ref) => {
    return (
        <NumericFormat
            ref={ref}
            allowNegative
            defaultValue={props.value ?? props.defaultValue}
            placeholder={props.placeholder}
            onValueChange={(values) => {
                onChange({ target: { value: values.floatValue } });
            }}
            className={cn(
                'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                className,
            )}
            customInput={Input}
        />
    );
});

export default NumberInput;
