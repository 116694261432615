import React from 'react';
import Routes from './Routes';
import RootLayout from '../layout/RootLayout';
import ErrorBoundary from './ErrorBoundary';

export const routes = [
    {
        element: <RootLayout />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                children: [Routes],
            },
        ],
    },
];
