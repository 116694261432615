import * as React from 'react';
import { useEffect } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';

import { cn } from '../../lib/utils';
import { Label } from './Label';
import { Button } from './Button';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormInput } from './FormInput';

const FormFieldContext = React.createContext({});

const FormField = ({ ...props }) => {
    return (
        <FormFieldContext.Provider value={{ name: props.name }}>
            <Controller {...props} />
        </FormFieldContext.Provider>
    );
};

const useFormField = () => {
    const fieldContext = React.useContext(FormFieldContext);
    const itemContext = React.useContext(FormItemContext);
    const { getFieldState, formState } = useFormContext();

    const fieldState = getFieldState(fieldContext.name, formState);

    if (!fieldContext) {
        throw new Error('useFormField should be used within <FormField>');
    }

    const { id } = itemContext;

    return {
        id,
        name: fieldContext.name,
        formItemId: `${id}-form-item`,
        formDescriptionId: `${id}-form-item-description`,
        formMessageId: `${id}-form-item-message`,
        ...fieldState,
    };
};

const FormItemContext = React.createContext({});

const FormItem = React.forwardRef(({ className, ...props }, ref) => {
    const id = React.useId();

    return (
        <FormItemContext.Provider value={{ id }}>
            <div ref={ref} className={cn('', className)} {...props} />
        </FormItemContext.Provider>
    );
});
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef(({ className, ...props }, ref) => {
    const { error, formItemId } = useFormField();

    return <Label ref={ref} className={cn(error && 'text-destructive', className)} htmlFor={formItemId} {...props} />;
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef(({ ...props }, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

    return (
        <Slot
            ref={ref}
            id={formItemId}
            aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
            aria-invalid={!!error}
            {...props}
        />
    );
});
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef(({ className, ...props }, ref) => {
    const { formDescriptionId } = useFormField();

    return <p ref={ref} id={formDescriptionId} className={cn('text-sm text-muted-foreground', className)} {...props} />;
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef(({ className, children, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error ? String(error?.message) : children;

    if (!body) {
        return null;
    }

    return (
        <p ref={ref} id={formMessageId} className={cn('text-sm font-medium text-destructive', className)} {...props}>
            {body}
        </p>
    );
});
FormMessage.displayName = 'FormMessage';

export { useFormField, FormProvider, FormItem, FormLabel, FormControl, FormDescription, FormMessage, FormField };

export default function Form({ fields, onSubmit, submitLabel, hideSubmitButton, formRef, error, children, className, submitClassName }) {
    const schema = z.object({
        ...fields.reduce((acc, field) => {
            acc[field.name] = field.schema;
            return acc;
        }, {}),
    });

    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: fields.reduce((acc, field) => {
            acc[field.name] = field.defaultValue ?? '';
            return acc;
        }, {}),
        mode: 'onBlur',
    });

    useEffect(() => {
        if (formRef != null) formRef.current = form;
    }, [form, formRef]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={className}>
                {fields.map((f) => {
                    return (
                        <FormField
                            {...f}
                            key={f.key}
                            control={form.control}
                            name={f.name}
                            render={({ field }) => {
                                return f.render ? f.render({ field: { ...field, ...f } }) : <FormInput {...{ ...field, ...f }} />;
                            }}
                        />
                    );
                })}
                {error && <FormMessage className={'pb-2'}>{error}</FormMessage>}
                {!hideSubmitButton && (
                    <Button className={`w-full mt-6 ${submitClassName}`} type="submit">
                        {submitLabel}
                    </Button>
                )}
            </form>
            {children}
        </FormProvider>
    );
}
