import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}
export const toProperCaseWithSpaces = (str = '') => {
    if (!str || !/\w/g.test(str)) return str;
    return str
        .replace(/[^a-zA-Z0-9]/g, ' ')
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .split(/\s+/)
        .map((w) => w[0].toUpperCase() + w.slice(1))
        .join(' ')
        .replace(/^./, function (str) {
            return str.toUpperCase();
        });
};
