import Logo from '../../assets/logo192.png';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <footer id="footer">
            <hr className="w-11/12 mx-auto" />

            <section className="container py-20 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-x-12 gap-y-8 text-foreground">
                <div className="col-span-full xl:col-span-2">
                    <Link to="/" className="flex items-center gap-3 font-semibold">
                        <img src={Logo} alt={'distillify-logo'} className="md:h-24 md:w-24 hidden md:block" />
                    </Link>
                </div>

                <div className="flex flex-col gap-2">
                    <h3 className="font-bold text-lg">Follow US</h3>
                    <div>
                        <a href="#" className="opacity-60 hover:opacity-100">
                            Instagram
                        </a>
                    </div>

                    <div>
                        <a href="#" className="opacity-60 hover:opacity-100">
                            Facebook
                        </a>
                    </div>
                </div>

                {/*<div className="flex flex-col gap-2">*/}
                {/*    <h3 className="font-bold text-lg">Platforms</h3>*/}

                {/*    <div>*/}
                {/*        <a href="#" className="opacity-60 hover:opacity-100">*/}
                {/*            Mobile*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="flex flex-col gap-2">
                    <h3 className="font-bold text-lg">Community</h3>

                    <div>
                        <a href="https://discord.gg/BJcTyng5Ak" className="opacity-60 hover:opacity-100">
                            Discord
                        </a>
                    </div>
                </div>
            </section>

            <hr className="w-11/12 mx-auto" />

            <section className="container  text-muted-foreground pb-24 text-center pt-4 w-full justify-between flex flex-row">
                <h3 className={'flex flex-row'}>
                    Distillify, Inc. &copy; 2024 <span className={'md:block hidden ml-1'}> - All Rights Reserved</span>
                </h3>

                <div className={'flex flex-row items-center'}>
                    <Link to={'/privacy'} className={`md:hidden flex items-center gap-3 rounded-lg px-3 transition-all hover:text-primary`}>
                        Privacy
                    </Link>
                    <Link to={'/privacy'} className={`md:flex hidden items-center gap-3 rounded-lg px-3 transition-all hover:text-primary`}>
                        Privacy Policy
                    </Link>
                    |
                    <Link to={'/terms'} className={`md:hidden flex items-center gap-3 rounded-lg px-3 transition-all hover:text-primary`}>
                        Terms
                    </Link>
                    <Link to={'/terms'} className={`md:flex hidden items-center gap-3 rounded-lg px-3 transition-all hover:text-primary`}>
                        Terms & Conditions
                    </Link>
                    |
                    <Link to={'/contact'} className={`md:hidden flex items-center gap-3 rounded-lg px-3 transition-all hover:text-primary`}>
                        Contact
                    </Link>
                    <Link to={'/contact'} className={`md:flex hidden items-center gap-3 rounded-lg px-3 transition-all hover:text-primary`}>
                        Contact Us
                    </Link>
                </div>
            </section>
        </footer>
    );
};
