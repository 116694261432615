import { addDoc, doc, collection, getDoc } from 'firebase/firestore';
import { firestore } from './firebase';

export const getPPFromFirestore = async () => {
    const pp = await getDoc(doc(firestore, 'legal', 'pp'));
    if (pp.exists()) {
        return pp.data();
    } else {
        console.log('No such document!');
    }
};

export const getTCFromFirestore = async () => {
    const tc = await getDoc(doc(firestore, 'legal', 'tc'));
    if (tc.exists()) {
        return tc.data();
    } else {
        console.log('No such document!');
    }
};

export const submitContactUsForm = async (formData) => {
    return await addDoc(collection(firestore, 'feedback'), formData);
};
