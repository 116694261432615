import * as React from 'react';
import { useState } from 'react';
import { Input } from './Input';
import { Avatar, AvatarFallback, AvatarImage } from './Avatar';
import { Image } from './Icon';
import { Dialog, DialogContent, DialogTrigger } from './Dialog';

const ImageInput = React.forwardRef(({ className, isLoading, value, defaultValue, ...props }, ref) => {
    const [imageSrc, setImageSrc] = useState();

    const onChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (loadEvent) => {
                const newImageSrc = loadEvent.target.result;
                setImageSrc(newImageSrc);
                props.onChange(newImageSrc);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <div className={'flex items-center gap-4'}>
                <Dialog className={'p-0 w-fit rounded-full'}>
                    <DialogTrigger>
                        <Avatar>
                            <AvatarImage alt={''} src={imageSrc || value || defaultValue} />
                            <AvatarFallback>
                                <Image />
                            </AvatarFallback>
                        </Avatar>
                    </DialogTrigger>
                    <DialogContent hideCloseIcon className={'p-0 w-fit sm:rounded-full rounded-full overflow-hidden'}>
                        <img className={'rounded-full h-[250px] w-[250px]'} src={imageSrc || value || defaultValue} alt={'whiskey photo'} />
                    </DialogContent>
                </Dialog>
                <Input accept="image/*" className={'text-foreground dark file:text-muted-foreground'} onChange={onChange} type={'file'} />
            </div>
        </div>
    );
});

export default ImageInput;
