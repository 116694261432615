import { logEvent, initializeAnalytics } from 'firebase/analytics';
import { firebaseApp } from './firebase';

const analytics = initializeAnalytics(firebaseApp);
export const trackDownload = ({ utm_source, utm_medium, utm_campaign }) => {
    logEvent(analytics, `download_from_${utm_source}`, {
        utm_source,
        utm_medium,
        utm_campaign,
    });
};
