import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/Card';
import { Facebook, Instagram, Linkedin } from '../ui/Icon';
import { buttonVariants } from '../ui/Button';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/Avatar';
import { User } from '../ui/Icon';

const teamList = [
    {
        imageUrl: '',
        name: 'Jay Korhonen',
        position: 'Founder & CEO',
        socialNetworks: [
            { name: 'Linkedin', url: 'https://www.linkedin.com/in/jay-korhonen/' },
            // {
            //     name: 'Facebook',
            //     url: 'https://www.facebook.com/',
            // },
            // {
            //     name: 'Instagram',
            //     url: 'https://www.instagram.com/',
            // },
        ],
    },
    {
        imageUrl: '',
        name: 'Mahmoud Kahack',
        position: 'Director of Engineering',
        socialNetworks: [],
    },
];

export const Team = () => {
    const socialIcon = (iconName) => {
        switch (iconName) {
            case 'Linkedin':
                return <Linkedin size="20" />;

            case 'Facebook':
                return <Facebook size="20" />;

            case 'Instagram':
                return <Instagram size="20" />;
        }
    };

    return (
        <section id="team" className="container py-24 sm:py-32">
            <div className={'w-full flex flex-col items-center justify-center'}>
                <h2 className="text-3xl md:text-4xl font-bold">
                    <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">Our Dedicated </span>
                    Team
                </h2>

                <p className="mt-4 mb-16 text-xl text-muted-foreground text-center md:text-start">
                    Meet the team that is working hard to bring you the best whiskey experience.
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 gap-y-10">
                {teamList.map(({ imageUrl, name, position, socialNetworks }) => (
                    <Card key={name} className="relative mt-8 flex flex-col justify-center items-center bg-card">
                        <CardHeader className="mt-8 flex justify-center items-center pb-2">
                            <Avatar className={'absolute -top-12 rounded-full w-24 h-24 aspect-square object-cover'}>
                                <AvatarImage src={imageUrl} alt={`${name} ${position}`} />
                                <AvatarFallback className={'bg-muted'}>
                                    <User size={48} />
                                </AvatarFallback>
                            </Avatar>
                            <CardTitle className="text-center">{name}</CardTitle>
                            <CardDescription className="text-primary">{position}</CardDescription>
                        </CardHeader>

                        <CardFooter>
                            {socialNetworks.map(({ name, url }) => (
                                <div key={name}>
                                    <a
                                        href={url}
                                        target="_blank"
                                        className={buttonVariants({
                                            variant: 'ghost',
                                            size: 'sm',
                                        })}
                                    >
                                        <span className="sr-only">{name} icon</span>
                                        {socialIcon(name)}
                                    </a>
                                </div>
                            ))}
                        </CardFooter>
                    </Card>
                ))}
            </div>
        </section>
    );
};
