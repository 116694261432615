import { NavigationMenu, NavigationMenuItem, NavigationMenuList } from '../ui/NavigationMenu';
import { ThemeToggle } from './ThemeToggle';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo192.png';

export const Navbar = () => {
    return (
        <header className="sticky border-b-[1px] top-0 z-40 w-full bg-background">
            <NavigationMenu className="mx-auto">
                <NavigationMenuList className="container h-14 px-4 w-screen flex justify-between ">
                    <NavigationMenuItem className="font-bold flex">
                        <Link to="/" className="ml-2 font-bold text-xl text-foreground flex items-center">
                            <img src={Logo} alt={'distillify-logo'} className="md:h-8 md:w-8 hidden md:block mr-4" />
                            Distillify
                        </Link>
                    </NavigationMenuItem>

                    <span className="flex md:hidden">
                        <ThemeToggle />
                    </span>

                    <nav className="hidden md:flex gap-2"></nav>

                    <div className="hidden md:flex gap-2">
                        <ThemeToggle />
                    </div>
                </NavigationMenuList>
            </NavigationMenu>
        </header>
    );
};
