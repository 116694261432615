import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../ui/Card';
import { Badge } from '../ui/Badge';

const features = [
    {
        title: 'Social Posts',
        description: 'Share your whiskey journey with the world. Post your favorite whiskeys and interact with the community.',
    },
    {
        title: 'Checkins',
        description: 'Checkin to whiskeys you have tried and share your thoughts.',
    },
    {
        title: 'Collection',
        description: 'Keep track of your whiskey collection and wishlist.',
    },
];

const featureList = [];

export const Features = () => {
    return (
        <section id="features" className="container py-24 sm:pt-16 space-y-16">
            <h2 className="text-3xl lg:text-4xl font-bold text-center">
                Many <span className="bg-gradient-to-b from-orange-500 to-primary text-transparent bg-clip-text"> Cool Features</span>
            </h2>

            <div className="flex flex-wrap md:justify-center gap-4">
                {featureList.map((feature) => (
                    <div key={feature}>
                        <Badge variant="secondary" className="text-sm">
                            {feature}
                        </Badge>
                    </div>
                ))}
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {features.map(({ title, description, image }) => (
                    <Card key={title}>
                        <CardHeader>
                            <CardTitle>{title}</CardTitle>
                        </CardHeader>

                        <CardContent className={'text-muted-foreground'}>{description}</CardContent>

                        <CardFooter>{/*<img src={image} alt="About feature" className="w-[200px] lg:w-[300px] mx-auto" />*/}</CardFooter>
                    </Card>
                ))}
            </div>
        </section>
    );
};
