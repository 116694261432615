import { Hero } from '../../components/landing/Hero';
import { About } from '../../components/landing/About';
import { Features } from '../../components/landing/Features';
import { Team } from '../../components/landing/Team';
import { Footer } from '../../components/landing/Footer';
import { ScrollToTop } from '../../components/landing/ScrollToTop';

const Landing = () => {
    return (
        <div className={'bg-background'}>
            <Hero />
            <About />
            <Features />
            <Team />
            <Footer />
            <ScrollToTop />
        </div>
    );
};

export default Landing;
