import { useRouteError } from 'react-router-dom';
import { toast } from 'sonner';
import { Navigate } from 'react-router';

const ErrorBoundary = () => {
    let error = useRouteError();

    toast.error(`${error.status} ${error.statusText}`, {
        id: 'error',
        duration: 7000,
    });

    return <Navigate to={'/'} />;
};

export default ErrorBoundary;
