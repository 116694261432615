import React from 'react';
import { Outlet } from 'react-router-dom';
import { Toaster } from '../components/ui/Sonner';
import { ThemeContext, ThemeProvider } from '../contexts/ThemeContext';
import { Navbar } from '../components/landing/Navbar';

const RootLayout = () => {
    return (
        <ThemeProvider>
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <div className={`${theme}`}>
                        <Navbar />
                        <Outlet />
                        <Toaster />
                    </div>
                )}
            </ThemeContext.Consumer>
        </ThemeProvider>
    );
};

export default RootLayout;
