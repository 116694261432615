import { useEffect, useState } from 'react';
import { getUserCount } from '../../lib/firebase/userService';
import { getWhiskeyCount } from '../../lib/firebase/whiskeyService';
import { getReviewCount } from '../../lib/firebase/activityService';

export const Statistics = () => {
    const [userCount, setUserCount] = useState(0);
    const [whiskeyCount, setWhiskeyCount] = useState(0);
    const [reviewCount, setReviewCount] = useState(0);

    useEffect(() => {
        const fetchStats = async () => {
            const uCount = await getUserCount();
            const wCount = await getWhiskeyCount();
            const rCount = await getReviewCount();
            setUserCount(uCount);
            setWhiskeyCount(wCount);
            setReviewCount(rCount);
        };

        fetchStats();
    }, []);

    const stats = [
        {
            quantity: `${Math.floor(userCount / 10) * 10} +`,
            description: 'Users',
        },
        {
            quantity: `${Math.floor(whiskeyCount / 100) * 100} +`,
            description: 'Whiskeys',
        },
        {
            quantity: `${Math.floor(reviewCount / 10) * 10} +`,
            description: 'Checkins',
        },
    ];

    return (
        <section id="statistics">
            <div className="grid grid-cols-3 gap-0 md:gap-8">
                {stats.map(({ quantity, description }) => (
                    <div key={description} className="space-y-2 text-center text-foreground">
                        <h2 className="text-3xl sm:text-4xl font-bold ">{quantity}</h2>
                        <p className="text-xl text-muted-foreground">{description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};
