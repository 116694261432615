import { Toaster as Sonner } from 'sonner';
import { useTheme } from '../../contexts/ThemeContext';

const Toaster = ({ ...props }) => {
    const { theme = 'system' } = useTheme();

    return (
        <Sonner
            theme={theme}
            className="toaster group"
            toastOptions={{
                unstyled: true,
                classNames: {
                    toast: 'absolute bottom-0 right-0 flex flex-row items-center px-4 py-2 rounded-md bg-background border-border shadow-xl',
                    icon: 'mr-4',
                    description: 'group-[.toast]:text-muted-foreground',
                    actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
                    cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
                    error: 'text-destructive-foreground bg-destructive ',
                    info: 'text-info-foreground bg-info',
                    success: 'text-success-foreground bg-success',
                    warning: 'text-warning-foreground bg-warning',
                },
            }}
            {...props}
        />
    );
};

export { Toaster };
