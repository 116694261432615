import * as React from 'react';
import { useEffect, useState } from 'react';
import { getTCFromFirestore } from '../../lib/firebase/legalService';

const PrivacyPolicy = () => {
    const [termsAndConditions, setTermsAndConditions] = useState(null);

    useEffect(() => {
        getTCFromFirestore().then((tc) => {
            setTermsAndConditions(tc);
        });
    }, []);

    useEffect(() => {
        if (!termsAndConditions) return;

        document.getElementById('terms-and-conditions').innerHTML = termsAndConditions.body;
    }, [termsAndConditions]);

    return <div className={'px-20 py-4'}>{termsAndConditions != null ? <div id={'terms-and-conditions'} /> : 'loading...'}</div>;
};

export default PrivacyPolicy;
