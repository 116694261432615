import { Statistics } from './Statistics';
import { useEffect, useState } from 'react';
import { getUserCount } from '../../lib/firebase/userService';

export const About = () => {
    return (
        <section id="about" className="container py-24 md:py-24 sm:pt-32">
            <div className="bg-card border rounded-lg py-12">
                <div className="px-6 flex flex-col-reverse md:flex-row gap-8 md:gap-12">
                    <div className="bg-green-0 flex flex-col justify-between">
                        <div className="pb-6 items-center flex text-center flex-col">
                            <span>
                                <h2 className="text-3xl md:text-4xl font-bold text-foreground">
                                    <span className="bg-gradient-to-b from-primary/60 to-primary text-transparent bg-clip-text">About </span>
                                    Us
                                </h2>
                            </span>
                            <p className="text-xl text-muted-foreground mt-4">
                                Distillify is a community-driven platform for whiskey enthusiasts to connect, share, review, and explore the world of
                                whiskey. Our mission is to provide a platform for whiskey lovers to share their passion and knowledge.
                            </p>
                        </div>

                        <Statistics />
                    </div>
                </div>
            </div>
        </section>
    );
};
