import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from './DropdownMenu';
import { Button } from './Button';
import { toProperCaseWithSpaces } from '../../lib/utils';
import { useTheme } from '../../contexts/ThemeContext';
import useOutsideClick from '../../hooks/useExternalClick';

const DropdownInput = React.forwardRef(({ className, isLoading, label, value, defaultValue, options, multiple = false, ...props }, ref) => {
    const { theme } = useTheme();
    const [values, setValues] = useState(value || defaultValue || []);
    const [open, setOpen] = useState(false);
    const menuRef = React.useRef(null);

    useOutsideClick(menuRef, () => setOpen(false));

    const onItemSelect = useCallback((isSelected, item) => {
        if (multiple) {
            if (isSelected) {
                setValues((old) => {
                    const newValues = [...old, item];
                    props.onChange({ target: { value: newValues } });
                    return newValues;
                });
            } else {
                setValues((old) => {
                    const newValues = old.filter((value) => {
                        return value !== item;
                    });
                    props.onChange({ target: { value: newValues } });
                    return newValues;
                });
            }
        } else {
            setValues([item]);
            props.onChange({ target: { value: item } });
        }
    }, []);

    return (
        <DropdownMenu open={open} className={`${className}`}>
            <DropdownMenuTrigger asChild className={'overflow-hidden'}>
                <Button variant="outline" className={'w-full overflow-hidden whitespace-nowrap flex'} onClick={() => setOpen((v) => !v)}>
                    <p className={'text-start overflow-ellipsis whitespace-nowrap justify-start items-start'}>
                        {values.length > 0
                            ? `${values
                                  .slice(0, 3)
                                  .map((v) => `${toProperCaseWithSpaces(v)}`)
                                  .join(', ')}${values.length > 3 ? ` +${values.length - 3}` : ''}`
                            : `Select ${label}`}
                    </p>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent ref={menuRef} className={`w-56 ${theme}`}>
                <DropdownMenuLabel>{label}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {options.map((option) => (
                    <DropdownMenuCheckboxItem key={option} checked={values.includes(option)} onCheckedChange={(v) => onItemSelect(v, option)}>
                        {option}
                    </DropdownMenuCheckboxItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
    );
});

export default DropdownInput;
