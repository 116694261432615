import React from 'react';

import Landing from '../pages/landing/Landing';
import PrivacyPolicy from '../pages/legal/PrivacyPolicy';
import TermsAndConditions from '../pages/legal/TermsAndConditions';
import ContactUs from '../pages/contact/ContactUs';
import Download from '../pages/download/Download';

const Routes = {
    path: '/',
    children: [
        {
            children: [
                {
                    path: '',
                    element: <Landing />,
                },
                {
                    path: 'privacy',
                    element: <PrivacyPolicy />,
                },
                {
                    path: 'terms',
                    element: <TermsAndConditions />,
                },
                {
                    path: 'contact',
                    element: <ContactUs />,
                },
                {
                    path: 'download',
                    element: <Download />,
                },
            ],
        },
    ],
};

export default Routes;
