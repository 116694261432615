import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/Card';
import { useCallback, useMemo, useRef, useState } from 'react';
import { submitContactUsForm } from '../../lib/firebase/legalService';
import { z } from 'zod';
import Form from '../../components/ui/Form';
import { toast } from 'sonner';

const ContactUs = () => {
    const [error, setError] = useState(null);
    const formRef = useRef(null);

    const fields = useMemo(
        () => [
            {
                name: 'name',
                type: 'name',
                label: 'Name',
                className: 'md:w-[300px]',
                placeholder: 'John Doe',
                schema: z.string({
                    required_error: 'Please enter a name',
                    invalid_type_error: 'Please enter a valid name',
                }),
            },
            {
                name: 'email',
                type: 'email',
                label: 'Email',
                className: 'md:w-[300px]',
                placeholder: 'me@distillify.com',
                schema: z.string().email({
                    required_error: 'Please enter an email',
                    invalid_type_error: 'Please enter a valid email',
                }),
            },
            {
                name: 'message',
                type: 'textarea',
                label: 'Message',
                className: 'md:w-[600px]',
                placeholder: 'Hello, I would like to know more about your services',
                schema: z.string({
                    required_error: 'Please enter a message',
                    invalid_type_error: 'Please enter a valid message',
                }),
            },
        ],
        [],
    );

    const onSubmit = useCallback((values) => {
        submitContactUsForm(values).then(() => {
            setError(null);
            toast.success('Your message has been sent');
            formRef.current.reset();
        });
    }, []);

    return (
        <div className={'bg-background flex justify-center items-center md:mt-8 m-2'}>
            <Card className={'md:w-fit w-full'}>
                <CardHeader>
                    <CardTitle>Contact Us</CardTitle>
                </CardHeader>
                <CardContent>
                    <Form formRef={formRef} submitClassName={'w-fit'} fields={fields} onSubmit={onSubmit} submitLabel={'Submit'} error={error} />
                </CardContent>
            </Card>
        </div>
    );
};

export default ContactUs;
