import { useEffect } from 'react';
import { trackDownload } from '../../lib/firebase/tracking';
import { isAndroid, isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const platform = isAndroid ? 'android' : isIOS ? 'ios' : 'unknown';

const Download = () => {
    const navigate = useNavigate();
    const gaCampaignParams = new URLSearchParams(window.location.search);
    const utm_source = gaCampaignParams.get('utm_source');
    const utm_medium = gaCampaignParams.get('utm_medium');
    const utm_campaign = gaCampaignParams.get('utm_campaign');

    useEffect(() => {
        if (utm_source && utm_medium && utm_campaign) {
            trackDownload({
                utm_source,
                utm_medium,
                utm_campaign,
            });
        }

        if (platform === 'android') {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.distillify.android';
        } else if (platform === 'ios') {
            window.location.href = 'https://apps.apple.com/us/app/distillify/id1628019809';
        } else {
            navigate('/');
        }
    }, [navigate, utm_campaign, utm_medium, utm_source]);

    return null;
};

export default Download;
