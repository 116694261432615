import { Moon, Sun } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/DropdownMenu';
import { Button } from '../ui/Button';

export function ThemeToggle() {
    const { theme, setTheme, systemTheme } = useTheme();

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className={'items-center justify-center flex'}>
                    {theme === 'light' ? (
                        <Sun className={`h-[1.2rem] w-[1.2rem]`} />
                    ) : (
                        <Moon className={`h-[1.2rem] w-[1.2rem] text-muted-foreground`} />
                    )}
                    <span className="sr-only">Toggle theme</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className={theme}>
                <DropdownMenuItem onClick={() => setTheme('light')}>Light</DropdownMenuItem>
                <DropdownMenuItem onClick={() => setTheme('dark')}>Dark</DropdownMenuItem>
                <DropdownMenuItem onClick={() => setTheme(systemTheme)}>System</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
