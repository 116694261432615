import * as React from 'react';
import { useEffect, useState } from 'react';
import { getPPFromFirestore } from '../../lib/firebase/legalService';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getPPFromFirestore()
            .then((pp) => {
                setPrivacyPolicy(pp);
            })
            .finally(() => {});
    }, []);

    useEffect(() => {
        if (!privacyPolicy) return;

        document.getElementById('privacy-policy').innerHTML = privacyPolicy.body;
    }, [privacyPolicy]);

    return <div className={'px-20 py-4'}>{privacyPolicy != null ? <div id={'privacy-policy'} /> : 'loading...'}</div>;
};

export default PrivacyPolicy;
