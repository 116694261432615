import {
    Bell,
    ChevronDown,
    CircleUser,
    DatabaseBackup,
    FileLock,
    GlassWater,
    Home,
    Image,
    LayoutList,
    LineChart,
    Loader2,
    Menu,
    Package,
    Package2,
    Plus,
    Search,
    ShoppingCart,
    Trash2,
    TriangleAlert,
    Users,
    X,
    Gift,
    Medal,
    Plane,
    Wallet,
    AreaChart,
    Facebook,
    Instagram,
    Linkedin,
    ArrowUpToLine,
    Check,
    Map,
    User,
} from 'lucide-react';

const Icon = {
    Loader2,
    LayoutList,
    TriangleAlert,
    DatabaseBackup,
    FileLock,
    GlassWater,
    Users,
    ChevronDown,
    Bell,
    CircleUser,
    Home,
    LineChart,
    Menu,
    Package,
    Package2,
    Search,
    ShoppingCart,
    Image,
    Plus,
    Trash2,
    X,
    Gift,
    Medal,
    Plane,
    Wallet,
    AreaChart,
    Facebook,
    Instagram,
    Linkedin,
    ArrowUpToLine,
    Check,
    Map,
    User,
};

export default Icon;

export {
    Loader2,
    LayoutList,
    TriangleAlert,
    DatabaseBackup,
    FileLock,
    GlassWater,
    Users,
    ChevronDown,
    Bell,
    CircleUser,
    Home,
    LineChart,
    Menu,
    Package,
    Package2,
    Search,
    ShoppingCart,
    Image,
    Plus,
    Trash2,
    X,
    Gift,
    Medal,
    Plane,
    Wallet,
    AreaChart,
    Facebook,
    Instagram,
    Linkedin,
    ArrowUpToLine,
    Check,
    Map,
    User,
};
