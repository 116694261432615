import { Link } from 'react-router-dom';
import AppStore from '../../assets/app-store-badge.svg';
import GooglePlay from '../../assets/google-play-badge.svg';

export const Hero = () => {
    return (
        <section className="container flex items-center py-24 justify-center h-[90vh] md:h-auto md:py-24">
            <div className="text-center items-center justify-center space-y-6 w-full">
                <main className="text-5xl md:text-6xl font-bold">
                    <h1 className="inline">
                        <span className="inline bg-gradient-to-r from-orange-500 to-primary text-transparent bg-clip-text">Distillify</span>
                    </h1>
                </main>

                <p className="text-xl text-muted-foreground md:w-1/2 mx-auto">
                    Connect, share, review, and explore the world of whiskey. Join our community of whiskey enthusiasts today and start your journey.
                </p>

                <div className={'flex pt-14 items-center justify-center gap-4 flex-col md:flex-row'}>
                    <Link to={'https://apps.apple.com/us/app/distillify/id1628019809'} className={'flex items-center justify-center w-[150px]'}>
                        <img src={AppStore} alt={'app-store'} className="w-full" />
                    </Link>
                    <Link
                        to={'https://play.google.com/store/apps/details?id=com.distillify.android'}
                        className={'flex items-center justify-center w-[170px]'}
                    >
                        <img src={GooglePlay} alt={'google-play'} className="w-full" />
                    </Link>
                </div>
            </div>

            <div className="shadow"></div>
        </section>
    );
};
