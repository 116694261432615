import * as React from 'react';
import { Input } from './Input';
import { FormControl, FormItem, FormLabel, FormMessage } from './Form';
import { Textarea } from './Textarea';
import NumberInput from './NumberInput';
import Icon from './Icon';
import { Button } from './Button';
import ImageInput from './ImageInput';
import DropdownInput from './DropdownInput';

const FormInput = React.forwardRef(({ type, label, error, options, multiple, ...props }, ref) => {
    if (props.value === undefined) return null;
    if (props.value != null) delete props.defaultValue;

    const inputProps = {
        onChange: props.onChange,
        onBlur: props.onBlur,
        className: props.className,
        placeholder: props.placeholder,
    };

    if (props.value == null) {
        inputProps.defaultValue = props.defaultValue;
    } else {
        inputProps.value = props.value;
    }

    if (type === 'readonly') {
        return (
            <InputWrapper {...props} label={label} error={error}>
                <p className={'text-muted-foreground text-sm'}>{props.value}</p>
            </InputWrapper>
        );
    } else if (type === 'textarea') {
        return (
            <InputWrapper {...props} label={label} error={error}>
                <Textarea {...inputProps} />
            </InputWrapper>
        );
    } else if (type === 'dropdown') {
        return (
            <InputWrapper {...props} label={label} error={error}>
                <DropdownInput {...inputProps} label={label} options={options} multiple={multiple} />
            </InputWrapper>
        );
    } else if (type === 'number') {
        return (
            <InputWrapper {...props} label={label} error={error}>
                <NumberInput {...inputProps} type={type} />
            </InputWrapper>
        );
    } else if (type === 'image') {
        return (
            <InputWrapper {...props} label={label} error={error}>
                <ImageInput {...inputProps} />
            </InputWrapper>
        );
    } else if (type === 'object') {
        inputProps.onBlur = (e) => {
            inputProps.onChange({ target: { value: e.target.value ? JSON.parse(e.target.value) : null } });
        };
        inputProps.defaultValue = inputProps.value ? JSON.stringify(inputProps.value) : inputProps.value;
        delete inputProps.value;
        return (
            <InputWrapper {...props} label={label} error={error}>
                <Input {...inputProps} type={type} />
            </InputWrapper>
        );
    } else {
        return (
            <InputWrapper {...props} label={label} error={error}>
                <Input {...inputProps} type={type} />
            </InputWrapper>
        );
    }
});
FormInput.displayName = 'FormInput';

export { FormInput };

const InputWrapper = ({ label, error, onRemove, containerClassName, children }) => {
    return (
        <FormItem className={`space-y-1 rounded-lg mb-2 py-0 pt-0 ${containerClassName}`}>
            <div className={'h-8 w-full flex flex-row items-center justify-between'}>
                <FormLabel>{label}</FormLabel>
                {onRemove != null && (
                    <Button
                        variant={'text'}
                        className={'p-0'}
                        onClick={(e) => {
                            e.preventDefault();
                            onRemove();
                        }}
                    >
                        <Icon.Trash2 className={'h-4 w-4 text-destructive'} />
                    </Button>
                )}
            </div>
            <FormControl>{children}</FormControl>
            <FormMessage>{error}</FormMessage>
        </FormItem>
    );
};
