import { createContext, useContext, useState } from 'react';

const ThemeContext = createContext({
    theme: 'light',
    setTheme: () => {},
});

const ThemeProvider = ({ children }) => {
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

    const [theme, setTheme] = useState(systemTheme);

    return <ThemeContext.Provider value={{ theme, setTheme, systemTheme }}>{children}</ThemeContext.Provider>;
};

const useTheme = () => {
    const context = useContext(ThemeContext);

    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }

    return context;
};

export { ThemeContext, ThemeProvider, useTheme };
